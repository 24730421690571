import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        status: 'loading',
        errorMessage: '',
        menuApp: [],
    },
    reducers: {
        setMenu: (state, { payload }) => {
            state.menuApp = payload.menu;
            state.status = payload.status;
            state.errorMessage = payload.errorMessage;
        },
    }
});


// Action creators are generated for each case reducer function
export const { setMenu } = appSlice.actions;