import { setMenu } from "./"
import { authApi } from "../../api/authApi";

export const getMenuUser = ({ email, menu }) => {

    return async( dispatch ) => {

        // Validar si existe un menu ya cargado
        if( menu.length ) return;

        return await authApi.post('/permisosMenuUsuario', { email })
        .then(resp => {
            return dispatch( setMenu({ menu: resp.data, status: 'loaded', errorMessage: '' }) );
        })
        .catch(error => {

            let response = error?.request?.response;

            if( JSON.parse( response ) ){
                response = JSON.parse( response )['message'];
            }

            return dispatch( setMenu({ menu: [], status: 'loaded', errorMessage: 'Error menú ' + response }) );
        })

    }

}
