import { checkingCredentials, logOut, logIn } from "./"
import { authApi } from "../../api/authApi";
import { useLocalStorage } from "../../hooks";
import { useCookies } from "../../hooks/useCookies";

export const startGoogleSingIn = ({ id_token }) => {
    return async( dispatch ) => {

        const { setValue } = useLocalStorage();
        const { setCookie } = useCookies();

        dispatch( checkingCredentials() );
        
        return await authApi.post('googleAuth', { id_token })
        .then((result) => {
            dispatch( logIn( result.data ) );

            // Indicar en el storage que el usuario esta logueado    
            setValue('isLoggedInGoogle', true);

            // Asignar Cookie de GoogleAuth con el token recibido
            setCookie({ cookieName: 'userAuthCun', cookieValue: encodeURI(JSON.stringify(result.data)), expires: result.data['expires_in'] });

        })
        .catch((error) => {

            let response = error?.request?.response;

            if( JSON.parse( response ) ){
                response = JSON.parse( response )['message'];
            }

            const errorMessage = 'Error al conectar al servicio de autenticación: ' + error.config.baseURL + ' - ' +  response;

            setValue('isLoggedInGoogle', false);

            return dispatch( logOut({ errorMessage }) );
        });

    }
}

export const startLoginFromCookie = ({ userAuth }) => {
    return async( dispatch ) => {

        if(!userAuth) return;

        const { setValue } = useLocalStorage();

        dispatch( checkingCredentials() );

        dispatch( logIn( userAuth ) );

        // Indicar en el storage que el usuario esta logueado    
        setValue('isLoggedInGoogle', true);        

    }    
}

export const startLogout = ({ errorMessage }) => {
    return async( dispatch ) => {

        const { setValue } = useLocalStorage();

        // Indicar que no esta logueado el usuario
        setValue('isLoggedInGoogle', false);

        // Accionar el store y eliminar datos de la sesion
        dispatch( logOut({ errorMessage }) );

    }
}