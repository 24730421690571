import { useEffect, useMemo, useState } from "react";

export const useForm = ( initialState = {}, formValidations = {} ) => {

    const [ formState, setFormState ] = useState( initialState );
    const [ formValidation, setFormValidation ] = useState({

    });

    useEffect(() => {
        createValidators();
    }, [ formState ]);

    const isFormValid = useMemo(() => {

        for (const formValue of Object.keys( formValidation )) {
            if( formValidation[formValue] !== null ) return false;
        }

        return true;
    }, [ formValidation ])

    const handleInputChange = ({ target }) => {

        const { name, value } = target;

        setFormState({
            ...formState,
            [ name ]: value
        });

    }

    const handleResetForm = () => {
        setFormState( initialState )
    }

    const createValidators = () => {

        const formCheckValues = {};

        for (const formField of Object.keys( formValidations )) {
            const [ fn, errorMessage ] = formValidations[formField];

            formCheckValues[`${ formField }Valid`] = fn( formState[formField] ) ? null : errorMessage;
        }

        setFormValidation( formCheckValues );

    }

    return {
        ...formState,
        formState,
        handleInputChange,
        handleResetForm,

        ...formValidation,
        isFormValid
    }
    
}