import { Grid } from '@mui/material'
import React from 'react'
import { DashboardLayout } from '../../dashboard/layout/DashboardLayout'

export const PagosPage = () => {

  return (
    <DashboardLayout>
      <Grid container sx={{ position: 'relative', overflow: 'hidden', width: '100%', pt: '56.25%', mt: 1 }}>
        <iframe 
          src="https://botondepago.cun.edu.co:8443/BotonPago/faces/index.xhtml" 
          style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, width: '100%', height: '100%', overflow: 'hidden' }}
          frameBorder='0'
          id="iframe_pagos"
          title='iframe_pagos'
          >
        </iframe>
      </Grid>
    </DashboardLayout>
  )
}
