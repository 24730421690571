import { Routes, Route, Navigate } from "react-router-dom";
import { AdminCertificadosPage, AdminCertificadosPagos, MisCertificadosPage, AdminCertificadosPantallaCompletaPage } from "../../Certificados/pages";
import { EstudianteCertificadosPage } from "../../Estudiantes/pages";
import { EstudianteGradosPage } from "../../Grados/pages";
import { PagosPage } from "../../Pagos/pages/PagosPage";
import { NothingSelectedView } from "../views";
import { EncuestaSatisfaccionPage } from "../../Estudiantes/pages/EncuestaSatisfaccionPage";
import { AsistenciaEstudiantePage } from '../../Docentes/pages';

export const DashboardRoutes = () => {

  return (
    <Routes>
          <Route path="/" element={ <NothingSelectedView /> }/>

          <Route path="admin/certificados" element={ <AdminCertificadosPage /> }/>
          <Route path="admin/certificados/pantallacompleta" element={ <AdminCertificadosPantallaCompletaPage /> }/>
          <Route path="admin/certificados/pagos" element={ <AdminCertificadosPagos /> }/>
          <Route path="admin/pagos" element={ <PagosPage /> }/>

          <Route path="estudiantes/grados" element={ <EstudianteGradosPage /> }/>
          <Route path="estudiantes/solicitudCertificados" element={ <EstudianteCertificadosPage /> }/>
          <Route path="estudiantes/misCertificados" element={ <MisCertificadosPage /> }/>
          <Route path="estudiantes/encuestaSatisfaccion" element={ <EncuestaSatisfaccionPage /> }/>

          <Route path="docente/asistenciaEstudiante" element={ <AsistenciaEstudiantePage /> }/>
          
          <Route path="/*" element={ <Navigate to="/" /> } />
    </Routes>
  )
}
