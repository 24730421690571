import React from 'react'
import { CircularProgress, Grid } from '@mui/material'

export const CheckingAuth = () => {
  return (
    <Grid 
        container
        spacing={ 0 }
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', backgroundColor: 'secondary.main', padding: 3 }}
    >

        <Grid 
            container
            direction="row"
            justifyContent="center"
        >   

            <CircularProgress sx={{ color: 'third.main' }}/>

        </Grid>
    </Grid>
  )
}
