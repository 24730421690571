import { useDispatch, useSelector } from 'react-redux';
import { MenuOutlined, LogoutOutlined } from "@mui/icons-material"
import { AppBar, IconButton, Toolbar, Grid, Typography } from "@mui/material"
import { startLogout } from '../../store/auth';
import { GoogleAuthLogOut } from '../../googleAuth/GoogleAuth';

export const NavBar = ({ drawerWidth = 240, openSidebar }) => {

    const { tipoUsuario, email } = useSelector(state => state.auth);

    const dispatch = useDispatch();

    const onLogOut = () => {

        GoogleAuthLogOut({ email });

        dispatch( startLogout({ errorMessage: '' }) );
    }   

    return (
    <AppBar 
            position="fixed" 
            sx={{ 
                backgroundColor: 'secondary.main', 
                color: 'white'
            }}
        >
        
        <Toolbar>
            <IconButton
                    color="inherit"
                    edge="start"
                    sx={{ mr: 2, display: { md: 'block' } }}
                    onClick={ () => openSidebar() }
                >
                <MenuOutlined />
            </IconButton>

            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                
                <Typography variant="h6" noWrap component="div"> CUN APP - { tipoUsuario || 'SIN ROL' }</Typography>
                
                <Typography edge="end" sx={{ color: 'third.main' }} component="a" onClick={ () => onLogOut() }>
                    <LogoutOutlined /> 
                </Typography>         

            </Grid>
        
        </Toolbar>
    </AppBar>
    )
}
