import { useEffect } from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";

import { GoogleCookieAuth } from "../googleAuth/GoogleAuth";
import { startLoginFromCookie } from "../store/auth";

export const useCheckAuth = () => {

    const  { status } = useSelector( state => state.auth );

    const dispatch = useDispatch();


    useEffect(() => {

      let userAuth = GoogleCookieAuth();
      if( userAuth ){
        userAuth = JSON.parse(decodeURI(userAuth))
      }

      dispatch( startLoginFromCookie({ userAuth }) );

    }, []);


  return {
    status
  }
}
