import { useDispatch, useSelector } from "react-redux";
import { Grid, Alert } from "@mui/material"
import { AuthLayout } from "../layout/AuthLayout";

import { startGoogleSingIn  } from "../../store/auth";

import { GoogleAuth } from "../../googleAuth/GoogleAuth";
import Swal from "sweetalert2";

export const LoginPage = () => {

    const { errorMessage } = useSelector( state => state.auth );

    const dispatch = useDispatch();

    const onLoginGoogle = ( credentials ) => {

        const { credential } = credentials;

        dispatch( startGoogleSingIn({ id_token: credential  }) );
    }

    const onErrorLoginGoogle = ( credentials ) => {

        if( !credentials || credentials.error ){
            console.error( 'Error de credenciales '+credentials.details );
            return;
        }

    }

    if( errorMessage.length ){

        Swal.fire({
            title: 'Error de autenticación',
            text: errorMessage,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#047835',
            confirmButtonText: 'Aceptar'
        });
    }

    return (

    <AuthLayout>
        <Grid container>

            <Grid container spacing={ 2 } sx={{ mb: 2, mt: 1 }}>

                <Grid item xs={ 12 } display={ !!errorMessage ? '' : 'none' }>
                    <Alert severity="error"
                        action={
                            <a href="/">Intentar de nuevo</a>
                        }
                    >
                    { errorMessage }
                    </Alert>
                </Grid>

            </Grid>

            <Grid item xs={ 12 } display={ !errorMessage ? '' : 'none' }>

                <GoogleAuth
                    onSuccess={onLoginGoogle}
                    onFailure={onErrorLoginGoogle}
                />

            </Grid>
        </Grid>

    </AuthLayout>

    )
}