import { useState } from 'react'
import { useSelector } from 'react-redux';
import { certificadosApi } from '../../api/certificadosApi';

import { Link as RouterLink } from "react-router-dom";
import { LoadingData } from '../../ui/components/LoadingData';
import { Alert, Button, Grid, Link } from '@mui/material';
import { Paid } from '@mui/icons-material';

export const EstudiantesAcciones = ({ camposFormularioBusqueda, identificacionEstudiante, id_tercero }) => {

  // Se extraen los datos del usuario desde el store
  const { nombres, apellidos, telefono, movil, direccion, email, tipIdentificacion } = useSelector( state => state.auth );

  // Estado de carga de datos
  const [ status, setStatus ] = useState(''); // loading - loaded

  const [ solicitud, setSolicitud ] = useState({ ok: false, message: '', accion: '' });

  // Se desestructuran los campos del formulario BusquedaEstudiante 
  const { tipoCertificado, periodoPrograma, ...rest } = camposFormularioBusqueda;

  // Accion para agregar el certificado al carrito
  const onPagarCertificado = async() => {
    
    setStatus('loading');

    const data = {
      ...tipoCertificado, // Se desestructura el objeto que contiene los datos relacionados al tipo de formulario (producto,  descripcion, valor_unidad,  tipo_producto)
      ...rest, // Se desestructura los campos restantes del formulario
      periodoPrograma: periodoPrograma?.cod_periodo,
      tipIdentificacion,
      identificacionEstudiante,
      id_tercero,
      nombres, 
      apellidos, 
      telefono, 
      movil, 
      direccion,
      email
    };

    await certificadosApi.post('/solicitarCertificado', { ...data })
    .then(resp => setSolicitud( resp.data ))
    .catch(error => {

      setStatus('loaded');
      
      setSolicitud({ ok: false, message: 'Ocurrio un error en la consulta' })

    });

    setStatus('loaded');
    
  }    

  const ValidacionSolicitudCertificado = ({ ok, message, accion }) => {

    const linkAccion    = (accion === 'pagar') ? '/admin/pagos' : (accion === 'certificado') ? '/estudiantes/misCertificados' : '';
    const mensajeAccion = (accion === 'pagar') ? 'Ir a pagar' : (accion === 'certificado') ? 'Ver certificado' : '';

    const severity = (ok === true) ? 'success' : 'warning';
    
    if( !message ){
      return '';
    }

    return (
      <Alert severity={ severity }        
        action={
          ( linkAccion ) && <Link component={ RouterLink } to={ linkAccion }> { mensajeAccion } </Link>  
        }
      >
        { message } 
      </Alert>
    )
  }


  if( status === 'loading' ){
    return (
      <Grid item xs={ 12 } sx={{ mt: 3 }}>
        <LoadingData />
      </Grid>
    )
  }

  return (
    <>
      <Grid item xs={ 12 } sx={{ mt: 3 }}>
          <Button
              type="button" 
              variant="contained" 
              fullWidth 
              size="large"
              startIcon={<Paid />}
              onClick={ () => onPagarCertificado() }
          >
            Generar solicitud de pago   
          </Button>
      </Grid> 

      <Grid item xs={ 12 } sx={{ mt: 3 }}>
        <ValidacionSolicitudCertificado ok={ solicitud.ok } message={ solicitud.message } accion={ solicitud.accion }/>
      </Grid>
    </>            
  )
}
