//External imports
import { useEffect, useRef } from 'react'
import { useLocalStorage } from '../hooks'
import { useCookies } from '../hooks/useCookies'

const src = 'https://accounts.google.com/gsi/client'
// const id = "132834471334-pbth4s65ictaissjn84maoqit1bf7fo8.apps.googleusercontent.com"
const id = "353318396148-nsnt72us02abr4uuarjn2f6g05f920h1.apps.googleusercontent.com"

const loadScript = (src) =>
new Promise((resolve, reject) => {
  if (document.querySelector(`script[src="${src}"]`)) return resolve()
  const script = document.createElement('script')
  script.src = src
  script.onload = () => resolve()
  script.onerror = (err) => reject(err)
  document.body.appendChild(script)
})

export const GoogleAuth = ({ onSuccess, onFailure }) => {

  const { getValue } = useLocalStorage();

  const googleButton = useRef(null);

  const isLoggedIn = ( getValue('isLoggedInGoogle') === 'true' ) ? true : false;

  useEffect(() => {

    loadScript(src)
      .then(() => {

        const googleOptions  = {
          client_id: id,
          callback: onSuccess,
          context: 'use',
          ux_mode: 'popup',
          auto_select: isLoggedIn,
          close_on_tap_outside: false
        };

        /*global google*/
        google.accounts.id.initialize(googleOptions);

        /* Si existe una sesion activa no se muestra el boton de Log In */
        if( !isLoggedIn ) {
          google.accounts.id.renderButton(
            googleButton.current,
            { theme: 'filled_blue', size: 'large', type: 'standard', shape: 'pill', logo_alignment: 'left' }
          );
        }

        google.accounts.id.prompt();

        })
      .catch((error) => onFailure( error ))

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`)
      if (scriptTag) document.body.removeChild(scriptTag)
    }
  }, [ isLoggedIn ])

  return (
    <div ref={ googleButton }></div>
  )

}

export const GoogleCookieAuth = () => {

  const { getCookie } = useCookies();

  // Verificar si existe una cookie llamada userAuthCun
  return getCookie('userAuthCun');

}

export const GoogleAuthLogOut = ({ email }) => {

  const { removeCookie } = useCookies();

  loadScript(src)
  .then(() => {

    /*global google*/
    google.accounts.id.initialize({client_id: id});

    google.accounts.id.revoke(email, done => {
      console.log(`Sesión ${ email } cerrada correctamente`);
    });

    // remover la cookie de sesion
    removeCookie('userAuthCun');

  })
  .catch(error => console.log( 'Error logout : ' + error ))

}
