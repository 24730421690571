import { StarOutline } from "@mui/icons-material"
import { Grid, Typography } from "@mui/material"
import { DashboardLayout } from "../layout/DashboardLayout"

export const NothingSelectedView = () => {
  return (
    <DashboardLayout>
      <Grid 
          container
          spacing={ 0 }
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 110px)',borderRadius: 3 }}
      >

          <Grid item xs={ 12 }>  
              <StarOutline sx={{ fontSize: 100, color: 'white' }}/>
              <img src="./images/logo_color.webp" alt="logo_cun" title="Logo Cun" style={{ width: '50%' }}/>
          </Grid>

          <Grid item xs={ 12 }>  
              <Typography color="white">Selecciona una opción del menú</Typography>
          </Grid>        

      </Grid>
    </DashboardLayout>
  )
}
