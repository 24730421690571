import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';

import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import ClearIcon from '@mui/icons-material/Clear';
import { Checkbox, FormControlLabel, Grid, RadioGroup, Typography, IconButton, InputBase, Paper } from '@mui/material';

const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon color="error" />,
      label: 'Muy insatisfecho',
    },
    2: {
      icon: <SentimentDissatisfiedIcon color="error" />,
      label: 'Insatisfecho',
    },
    3: {
      icon: <SentimentSatisfiedIcon color="warning" />,
      label: 'Neutral',
    },
    4: {
      icon: <SentimentSatisfiedAltIcon color="success" />,
      label: 'Satisfecho',
    },
    5: {
      icon: <SentimentVerySatisfiedIcon color="success" />,
      label: 'Muy Satisfecho',
    },
};  



const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconFilled .MuiSvgIcon-root': {
      fontSize: '10vw',
      marginLeft: '2.1vw'
    },  
    '& .MuiRating-iconEmpty .MuiSvgIcon-root ': {
      color: theme.palette.action.disabled,
      fontSize: '10vw',
      marginLeft: '2.1vw'
    },
    '& .MuiTypography-root ': {
      color: 'black',
    },
}));

export const TipoRespuestaPregunta = ({ pregunta, respuestaSeleccionada, textosEmojis, handleTextboxChange}) => {

  const [ checkboxOptions, setCheckboxOptions ] = useState([
    { title: 'Contenidos', value: '' },
    { title: 'Manteriales de apoyo', value: '' },
    { title: 'Didáctica entretenida', value: '' },
    { title: 'Más práctica', value: '' },
    { title: 'Disminuir la carga académica', value: '' },
    { title: 'Brindar mayor retroalimentación', value: '' },
    { title: 'Dedicar más tiempo a los temas de la clase', value: '' },
    { title: 'Avanzar con más agilidad en los temas', value: '' },
    { title: 'La clase estuvo muy bien', value: '' }
  ])
  
  // Estados para manejar la visibilidad de la caja de texto
  const [showTextbox, setShowTextbox] = useState(false)
  const [textboxValue, setTextboxValue] = useState(pregunta.setTextboxValue || '');
  const maxChars = 100;
  
  useEffect(() => {
    // Validar si se debe mostrar la caja de texto al regresar de pregunta
    if ( pregunta.value === 1 || pregunta.value === 2 ) {
      setShowTextbox(true);
      setTextboxValue(pregunta.textboxValue || '');
    } else {
      setShowTextbox(false);
      setTextboxValue('');
    }
    setTextboxValue(pregunta.textboxValue || '');
  }, [pregunta]);


  // Mantener el valor seleccionado de la pregunta
  const value = useMemo(() => pregunta.value, [ pregunta ]);

  const IconContainer = (props) => {
    const { value, ...other } = props;

    const textoIcon = textosEmojis[value - 1] || customIcons[value].label;
    
    return <>
      <table style={{ width: '100%', margin: 5, textAlign: 'center' }}>
        <tbody>
          <tr><td style={{ padding: '1px 1px 1px 3px' }}><span {...other}>{ customIcons[value].icon }</span></td></tr>
          <tr><td style={{ right: 1 }}><Typography variant="subtitle2" sx={{ mt: 1 }}>{ textoIcon }</Typography></td></tr>
        </tbody>
      </table>
    </>
  } 

  const handleRespuesta = (respuesta) => {

    let respuestasArr = (Array.isArray(pregunta.value)) ? [...pregunta.value] : [];

    // Se busca el valor selccionado del Checkbox en el array
    const respuestaIndex = respuestasArr.findIndex(resp => resp === respuesta);

    // Maneja si se muestra la caja de texto
    if (pregunta.tipo === 'likert') {
      if (respuesta === 1 || respuesta === 2) {
        setShowTextbox(true);
      } else {
        setShowTextbox(false);
        handleTextboxChange(pregunta.id, '');
      }
      respuestaSeleccionada({ preguntaId: pregunta.id, respuesta });
    }

    if(pregunta.tipo === 'checkbox'){

      const data = [...checkboxOptions];

      const respCheck = data.find(resp => resp.title === respuesta);
  
      respCheck.value = respuesta;
  
      setCheckboxOptions(data);      

      // Si existe un valor ya seleccionado entonces se elimina de la lista
      if( respuestaIndex >= 0 ){
        respuestasArr.splice(respuestaIndex, 1);
      }else{
        respuestasArr.push(respuesta);
      }

      // Si no hay nada seleccionado el Value se deja vacio
      if( !respuestasArr.length ) respuestasArr = null;
      
      return respuestaSeleccionada({ preguntaId: pregunta.id, respuesta: respuestasArr });
    }

    if(pregunta.tipo === 'text'){
      console.log(respuesta);
      
    }

    respuestaSeleccionada({ preguntaId: pregunta.id, respuesta });
    
  }

  // Validar si se fue seleccionada el mpaximo de respuestas
  const respuestasMaximasPermitidas = (((Array.isArray(pregunta.value)) ? pregunta.value.length : 0) >= 4) ? true : false;

  // Limpiar las respuestas de los checkbox seleccionados
  const limpiarCheckbox = () => {
    respuestaSeleccionada({ preguntaId: pregunta.id, respuesta: '' });

    const listaCheck = [...checkboxOptions];
    setCheckboxOptions([]);

    setTimeout(() => { 
      setCheckboxOptions(listaCheck);
    }, 100);
  }
 
  return (
    <>
        {
            // Tipo de calificacion de caras
            pregunta.tipo === 'likert' &&
            <>
                <StyledRating
                    onChange={(event, newValue) => handleRespuesta(newValue)}
                    name="highlight-selected-only"
                    defaultValue={pregunta.value}
                    IconContainerComponent={IconContainer}
                    getLabelText={(value) => customIcons[value].label}
                    highlightSelectedOnly
                />
                {showTextbox && (
                    <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
                      <Paper
                          component="div"
                          sx={{ p: '3px 5px', display: 'flex', alignItems: 'center', width: '100%' }}
                      >
                          <InputBase
                              sx={{ ml: 1, flex: 1 }}
                              placeholder="Ingresa tu respuesta"
                              fullWidth
                              label="Ingresa tu respuesta"
                              type="text"
                              name="respuestaTexto"
                              autoComplete="off"
                              value={textboxValue}
                              onChange={(event) => {
                                setTextboxValue(event.target.value);
                                handleTextboxChange(pregunta.id, event.target.value);
                              }}
                              inputProps={{ maxLength: maxChars }}
                          />
                          <Typography variant='caption' sx={{ ml: 2 }}>
                            {textboxValue.length}/{maxChars}
                          </Typography>
                      </Paper>
                  </Grid>
                )}
            </>
        }
        {
            // Tipo de calificacion de radio
            pregunta.tipo === 'checkbox' &&
            <>
                <form action="">
                    <Grid sx={{ textAlign: 'left' }}>
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="Limpiar campos seleccionado de la encuesta"
                            aria-haspopup="true"
                            onClick={() => limpiarCheckbox()}
                            sx={{ color: 'primary.main' }}
                            type="reset"
                        >
                            <ClearIcon /> Limpiar campos seleccionados
                        </IconButton>
                    </Grid>

                    <RadioGroup
                        aria-labelledby={'radio-buttons-group-label' + pregunta.id}
                        name={'checkbox_' + pregunta.id}
                    >
                        {
                            checkboxOptions.map(check =>
                                <FormControlLabel
                                    key={check.title}
                                    control={
                                        <Checkbox value={check.title}
                                            onChange={event => handleRespuesta(event.target.value)}
                                        />
                                    }
                                    label={check.title}
                                    disabled={(check.title !== 'La clase estuvo muy bien') ? respuestasMaximasPermitidas : false}
                                    value={check.title}
                                />
                            )
                        }
                    </RadioGroup>
                </form>
            </>
        }
        {
            // Tipo de calificacion de radio
            pregunta.tipo === 'text' &&
            <>
                <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
                    <form>
                        <Paper
                            component="div"
                            sx={{ p: '3px 5px', display: 'flex', alignItems: 'center', width: '100%' }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Ingresa tu respuesta"
                                fullWidth
                                label="Ingresa tu respuesta"
                                type="text"
                                name="respuestaTexto"
                                autoComplete="off"
                                onChange={(event) => handleRespuesta(event.target.value)}
                                inputProps={{ maxLength: 100 }}
                            />
                        </Paper>
                    </form>
                </Grid>
            </>
        }
    </>
)

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};

}