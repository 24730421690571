import PropTypes from 'prop-types';
import { useState } from 'react';

import { LoadingData } from '../../ui/components/LoadingData';

export const VisorCertificado = ({ urlCertificado }) => {

    const [ cargandoCertificado, setCargandoCertificado ] = useState(true);

    return (
        <>
            { ( cargandoCertificado ) && ( <LoadingData /> ) }
            <iframe src={ urlCertificado } style={{ width: '100%', height: '1000px', border: 0 }} onLoad={ () => setCargandoCertificado( false ) } title="generador_certificados"/>
        </>
    )
}

VisorCertificado.propTypes = {
    urlCertificado: PropTypes.string, 
}
