import React, { useState } from 'react'

import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import AssignmentIcon from '@mui/icons-material/Assignment';

export const ItemEncuesta = ({ clase, mostrarEncuestaClase }) => {

  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
      setIsHover(true);
  };

  const handleMouseLeave = () => {
      setIsHover(false);
  };  

  const PrimaryText = () => {
    return (
      <>
        <p>
          { (clase.hora_ini == null) ? 'Asistencia virtual' : `Fecha y hora asistencia: ${ clase.fecha_asistencia } ${clase.hora_ini} / ${clase.hora_fin}` }
          <br/>Docente: { clase.nom_docente }
          <br/>Unidad: { clase.cod_unidad } { clase.nom_unidad }
          <br/>materia: { clase.cod_materia } { clase.nom_materia }
          <br/>{ ( clase.fecha_presentacion ) && `Fecha presentación: ${ clase.fecha_presentacion }` }
        </p>
      </>
    )
  }

  return (
    <>

      <ListItem key={ clase.hora_ini } onClick={ () => mostrarEncuestaClase( clase ) } sx={{ 
            backgroundColor: 'secondary.main', 
            color: isHover ? 'primary.main' : 'white', 
            borderRadius: '50px', 
            mb: 2, 
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}          
          >
          <ListItemAvatar>
              <Avatar alt="Profile Picture" sx={{ bgcolor: ( (clase.calificado === '0' ) ? '#202A37' : 'primary.main' )  }}>
                  <AssignmentIcon />
              </Avatar>
          </ListItemAvatar>
          
          <ListItemText 
            secondaryTypographyProps={{ color: isHover ? 'secondary.main' : 'white' }}
            primary={ <PrimaryText/> } 
          />
      </ListItem>

    </>
  )
}
