import React, { useEffect, useState } from 'react'
import { Encuesta } from '../components';
import { useParams, useSearchParams } from 'react-router-dom';

import { AppBar, Container, Toolbar, Typography } from '@mui/material';
import AdbIcon from '@mui/icons-material/Adb';

import { notificacionesApi } from '../../api/notificacionesApi';
import { LoadingData } from '../../ui/components/LoadingData';
import Swal from 'sweetalert2';

export const EncuestaSatisfaccionNotificacionPage = () => {

  const { idAsistencia } = useParams();

  const [searchParams] = useSearchParams();

  const parametrosUrl = Object.fromEntries(searchParams);

  const {
    cod_unidad,
    cod_materia,
    doc_docente,
    nom_docente,
    hora_ini,
    hora_fin,
    doc_estudiante,
    correo_estudiante,
    nom_estudiante,
    primer_nom_estudiante,
    primer_ape_estudiante,
    asistencia,
    fecha_asistencia,
    calificado,
    id_asistencia,  
    id_notificacion,
  } = parametrosUrl;

  const [ encuestaCalificada, setEncuestaCalificada ] = useState('Y');

  const [ verificandoEncuesta, setVerificandoEncuesta ] = useState('cargando');

  const [ claseSeleccionada, setClaseSeleccionada ] = useState({
    "identificacion": doc_estudiante,
    "cod_unidad": cod_unidad,
    "cod_materia": cod_materia,
    "doc_docente": doc_docente,
    "nom_docente": nom_docente,
    "hora_ini": hora_ini,
    "hora_fin": hora_fin,
    "asistencia": asistencia,
    "fecha_asistencia": fecha_asistencia,
    "id": id_asistencia,
    "calificado": calificado
  });

  const validarIdNotificacion = () => {
    setVerificandoEncuesta('cargando');

    const body = {
        idNotificacion: id_notificacion || '',
        idAsistencia: id_asistencia,
    };

    notificacionesApi.post('validarIdNotificacionCalificarDocente', body)
    .then(resp => {
        setEncuestaCalificada(resp.data['notificacion_calificada']);
        setVerificandoEncuesta('');
    })
    .catch(error => {
        Swal.fire('Validación de encuesta','Ocurrio un error validando los parametros de la encuesta, intenta de nuevo','warning');
        setVerificandoEncuesta('');
    });
  }

  const Contenido = () => {

    if(verificandoEncuesta == 'cargando'){
        return <>
            <Typography variant='subtitle1'>Verificando validez de la encuesta, por favor espere...</Typography>
            <LoadingData />
        </>
    }

    if(encuestaCalificada == 'Y') {
        return <Typography variant='subtitle1'>Agradecemos tu participación, la encuesta ya fue respondida.</Typography>
    }

    return (
        <>
            <Typography variant="subtitle1">Agradecemos tu participación, a continuación te presentamos la información y encuesta sobre la clase recibida.</Typography>
            <Encuesta 
                claseSeleccionada={ claseSeleccionada }
                consultarAsistenciaClases={ validarIdNotificacion }   
                mostrarEncuestaClase={ setClaseSeleccionada } 
            ></Encuesta>        
        </>
    );

  }

  useEffect(() => {
      validarIdNotificacion();
  }, [ idAsistencia ]);

  return (
    <>
        <AppBar position="static" color="secondary">
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ p: 2 }}>
                    <img src={`${process.env.PUBLIC_URL + '/images/logo_color.webp'}`} alt="logo cun" width={100}/>
                    
                    <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                        mr: 2,
                        display: { xs: 'flex', md: 'none' },
                        flexGrow: 1,
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                        }}
                    >
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>


        <Container sx={{ mt: 5 }}>

            <Typography variant="h5">Hola, {primer_nom_estudiante} {primer_ape_estudiante}</Typography>
                         
            <Contenido/>
           
        </Container>

    </>
  )
}