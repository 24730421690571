import { useState } from "react";
import { Box, Toolbar } from "@mui/material";
import { NavBar, SideBar } from "../components";

const drawerWidth = 240;

export const DashboardLayout = ({ children }) => {

  const [ sidebarstatus, setSidebarstatus ] = useState( false );

  const openSidebar = () => {
    setSidebarstatus( !sidebarstatus );
  }

  return (
    <Box sx={{ display: 'flex' }} className="animate__animated animate__fadeIn">

        <NavBar drawerWidth={ drawerWidth }  openSidebar={ openSidebar } />

        <SideBar drawerWidth={ drawerWidth } openSidebar={ openSidebar } isOpenSidebar={ sidebarstatus } />

        <Box 
            component='main'
            sx={{ flexGrow: 1, p: 1 }}
        >

            <Toolbar />

            { children }

        </Box>

    </Box>
  )
}
