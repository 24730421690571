import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Grid, TextField, Typography, Button, Link, Alert } from "@mui/material"
import { AuthLayout } from "../layout/AuthLayout";
import { useForm } from "../../hooks";
import { useMemo, useState } from "react";

export const RegisterPage = () => {

    const { status, errorMessage } = useSelector( state => state.auth );

    const isCheckingAuthetication = useMemo( () => status === 'checking', [ status ]);

    const [ formSubmitted, setFormSubmitted ] = useState(false);

    const formData = {
        displayName: '', 
        email: '', 
        password: ''
    }

    const formValidation = {
        displayName: [ (value) => value.length >= 1, 'El nombre es obligatorio.' ],
        email: [ (value) => value.includes('@'), 'El correo debe tener un @.' ], 
        password: [ (value) => value.length >= 6, 'El password debe tener más de 6 letras.' ],        
    }

    const { 
        displayName, 
        email, 
        password, 
        handleInputChange,
        isFormValid,
        displayNameValid,
        emailValid,
        passwordValid,
    } = useForm(formData, formValidation);

    const onSumbit = ( event ) => {
        event.preventDefault();

        setFormSubmitted(true);

        if( !isFormValid || isCheckingAuthetication ) return;

    }

    return (
        <AuthLayout title="Register">

            <form onSubmit={ onSumbit }>
                <Grid container>
                    <Grid item xs={ 12 } sx={{ mt: 3 }}>
                        <TextField 
                            label="Nombre completo" 
                            type="text" 
                            placeholder="Nombre completo"
                            fullWidth
                            name="displayName"
                            onChange={ handleInputChange }
                            value={ displayName }
                            error={ !!displayNameValid && formSubmitted }
                            helperText={ displayNameValid }
                            />
                    </Grid>

                    <Grid item xs={ 12 } sx={{ mt: 3 }}>
                    <TextField 
                        label="correo" 
                        type="email" 
                        placeholder="correo@google.com"
                        fullWidth
                        name="email"
                        onChange={handleInputChange  }
                        value={ email }  
                        error={ !!emailValid && formSubmitted }
                        helperText={ emailValid }                                              
                        />
                    </Grid>                   

                    <Grid item xs={ 12 } sx={{ mt: 3 }}>
                        <TextField 
                            label="Contraseña" 
                            type="password" 
                            placeholder="Contraseña"
                            fullWidth
                            name="password"
                            onChange={handleInputChange  }
                            value={ password }   
                            error={ !!passwordValid && formSubmitted }
                            helperText={ passwordValid }  
                            autoComplete="off"                                                    
                            />
                    </Grid>  

                    <Grid container spacing={ 2 } sx={{ mb: 2, mt: 1 }}>

                        <Grid item xs={ 12 } display={ !!errorMessage ? '' : 'none' }>
                            <Alert severity="error">{ errorMessage }</Alert>
                        </Grid>

                        <Grid item xs={ 12 }>
                            <Button type="submit" variant="contained" fullWidth disabled={ isCheckingAuthetication }>Crear cuenta</Button>
                        </Grid>

                        <Grid container direction="row" justifyContent="end" sx={{ mt: 2 }}>
                            <Typography>¿Ya tienes cuenta?&nbsp;</Typography>
                            <Link component={ RouterLink } color="inherit" to="/auth/login">
                                Iniciar sesión
                            </Link>
                        </Grid>
                    </Grid>                      
                </Grid>
            </form>
        
        </AuthLayout>
    )
}