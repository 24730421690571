import { useEffect, useState } from 'react';

import { Alert, Button, Grid } from '@mui/material';
import { VisorCertificado } from './VisorCertificado';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Swal from 'sweetalert2';
import { EmailOutlined } from '@mui/icons-material';
import { notificacionesApi } from '../../api/notificacionesApi';

export const AdminAcciones = ({ 
    camposFormularioBusqueda, 
    identificacionEstudiante, 
    id_tercero,
    nombres,
    apellidos,
    email,
    telefono,
    movil,
    direccion = '',   
    botonesAccion = 'generar', 
  }) => {

  const [ generarCertificado, setGenerarCertificado ] = useState(false);

  const [ certificadoEnviado, setCertificadoEnviado ] = useState(false);

  const { tipoCertificado, periodoPrograma, programaEstudiante } = camposFormularioBusqueda;

  const [ urlCertificado, setUrlCertificado ] = useState('');

  const fechaGeneracion = periodoPrograma?.fecha_registro;

  const parametros = { 
    tipoCertificado : tipoCertificado?.tipo_producto ,
    identificacion : identificacionEstudiante ,
    terceroId : id_tercero ,
    nombres : nombres ,
    apellidos : apellidos ,
    telefono : telefono ,
    movil : movil ,
    codUnidad : programaEstudiante ,
    codPeriodo : periodoPrograma?.cod_periodo ,
    producto : tipoCertificado?.producto ,
    valor_unidad : tipoCertificado?.valor_unidad ,
    descripcion : tipoCertificado?.descripcion,
    id_generacion : '',  
    email : email ,
    direccion : direccion?.trim().replace("\n", "").replace("#", "N"),
    modo: 'visualizar',
    almacenar: true,
  };   

  const generarUrlCertificado = (parametrosUrl) => {

    // Generar la URL con los parametros GET requeridos para generar el certificado
    let url = new URL(`${process.env.REACT_APP_API_URL}/certificados/generarCertificadoAdmin/${tipoCertificado?.tipo_producto}`);
    url.search = new URLSearchParams(parametrosUrl);
    setUrlCertificado(url.href);
    
    console.log( url.search, urlCertificado );

  }

  const validarCertificadoCreado = async() => {

    let parametrosUrl = {...parametros};

    // Cuando se encuentra un certificado YA generado , se valida con el usuario que si quiere renovarlo o dejar el ultimo
    if( periodoPrograma?.id_generacion ){

        console.log(tipoCertificado?.tipo_producto);

        let tipoCertText = '';

        if (tipoCertificado?.tipo_producto === 'pagomatricula') {
            tipoCertText = 'pago de matricula';
        } else if (tipoCertificado?.tipo_producto === 'intensidadhoraria') {
            tipoCertText = 'intensidad horaria';
        } else if (tipoCertificado?.tipo_producto === 'culminacionasignatura') {
            tipoCertText = 'culminacion de asignatura';
        } else if (tipoCertificado?.tipo_producto === 'fechagrado') {
            tipoCertText = 'fecha de grado';
        } else if (tipoCertificado?.tipo_producto === 'registrosnies') {
            tipoCertText = 'registro SNIES';
        } else {
            tipoCertText = tipoCertificado?.tipo_producto;
        }
        

        await Swal.fire({
            text: `El certificado "${ tipoCertText }" seleccionado fue generado el ${ fechaGeneracion }, ¿desea generar uno nuevo?`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#047835',
            confirmButtonText: 'Si, generar uno nuevo',
            cancelButtonColor: '#B80812',
            cancelButtonText: 'No, mantener el último certificado',            
        }).then(resp => {

            if( resp.isConfirmed ){
                parametrosUrl.id_generacion = periodoPrograma?.id_generacion;
            }

        });
    }

    generarUrlCertificado(parametrosUrl);

  }

  const onVisualizarCertificado = async() => {

    if( generarCertificado === true ) setGenerarCertificado( false );

    validarCertificadoCreado();

    setGenerarCertificado( true );

  }

  const onGenerarEnviarPdf = async() => {

    let parametrosUrl = {...parametros};
    parametrosUrl.modo = 'enviar';

    await Swal.fire({
        text: `Esta seguro de enviar el certificado al correo ${ email } `,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#047835',
        confirmButtonText: 'Si, enviar certificado',
        cancelButtonColor: '#B80812',
        cancelButtonText: 'No, no enviar',            
    }).then(async(resp) => {

        // Confirmar Modal para enviar correo
        if( resp.isConfirmed ){

            Swal.fire({ 
                title: 'Cargando',
                text: 'Enviando certificado, por favor espere....', 
                showConfirmButton: false, 
                showCancelButton: false,
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false
            });

            setGenerarCertificado( false ); // Se indica que no se requiere mostrar el certificado en pantalla

            notificacionesApi.post('notificarCertificadoEstudiante', parametrosUrl)
            .then(() => {
                Swal.close();
                setCertificadoEnviado(true);
            })
            .catch((error) => {
                Swal.close();
                Swal.fire('Envió de certificado', 'No se logro enviar el certificado, intente de nuevo, error: '+error, 'error')
            });

        }

    }) 


  }

  // Al haber cambios en el formulario la visualización del formulario queda inactiva
  useEffect(() => {
      setGenerarCertificado( false );
      setCertificadoEnviado( false );
  }, [ camposFormularioBusqueda ]);  
  
  return (
    <>
            {
                (botonesAccion  === 'generar') &&
                <Grid item xs={ 12 } sx={{ mt: 3 }}>
                    <Button
                        type="button" 
                        variant="contained" 
                        fullWidth 
                        size="large"
                        startIcon={<PictureAsPdfIcon />}
                        onClick={ () => onVisualizarCertificado() }
                    >
                        Generar certificado   
                    </Button>
                
                </Grid>            
            }

            {
                (botonesAccion === 'enviar' && !certificadoEnviado) ?
                    <Grid item xs={ 12 } sx={{ mt: 3 }}>
                        <Button
                            type="button" 
                            variant="contained" 
                            fullWidth 
                            size="large"
                            startIcon={<EmailOutlined />}
                            onClick={ () => onGenerarEnviarPdf() }
                        >
                            Enviar certificado   
                        </Button>  
                    </Grid>

                    : ((botonesAccion === 'enviar' && certificadoEnviado)) &&

                    <Grid item xs={ 12 } sx={{ mt: 3 }}>
                        <Alert>{`El certificado fue enviado al correo ${ email }.`}</Alert>
                    </Grid>

                
            }

            <Grid item xs={ 12 } sx={{ mt: 3 }}>
                {
                    ( generarCertificado ) &&
                    <VisorCertificado urlCertificado={ urlCertificado } />
                }                            
            </Grid>    
    
    </>
  )
}
