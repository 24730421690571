import { Grid, Typography } from "@mui/material"

export const AuthLayout = ({ children, title = '' }) => {
  return (
    <Grid 
        container
        spacing={ 0 }
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', backgroundImage: `url("/images/banner-homeppal.webp")`, padding: 3, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
        className="animate__animated animate__fadeIn"
    >

        <Grid 
            item
            //className="box-shadow"
            xs={ 3 }
            sx={{ 
                //width: { md: 450 },
                //backgroundColor: 'white', 
                padding: 3, 
                borderRadius: 2,
                textAlign: 'center'
            }}
        >  
        
            <Typography variant="h5" sx={{ mb: 1 }}>{ title }</Typography>

            { children }

        </Grid>

    </Grid>
  )
}
