import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'not-authenticated', // cheking, not-authenticated, authenticated
        errorMessage: '',
        uid: '',
        email: '',
        displayName: '',
        photoURL: '',
        idTercero: '',
        numIdentificacion: '',
        tipIdentificacion: '',
        nombres: '',
        apellidos: '',
        telefono: '',
        movil: '',
        direccion: '',        
        genTercero: '',
        tipoUsuario: '',
    },
    reducers: {
        logIn: (state, { payload }) => {
            state.status = 'authenticated';
            state.uid = payload.uid;
            state.email = payload.email;
            state.displayName = payload.displayName;
            state.photoURL = payload.photoURL;
            state.errorMessage = payload?.errorMessage;
            state.idTercero = payload.idTercero;
            state.numIdentificacion = payload.numIdentificacion;
            state.tipIdentificacion = payload.tipIdentificacion;
            state.genTercero = payload.genTercero;           
            state.tipoUsuario = payload.tipoUsuario;     
            state.nombres = payload.nombres;
            state.apellidos = payload.apellidos;
            state.telefono = payload.telefono;
            state.movil = payload.movil;
            state.direccion = payload.direccion;                  
        },
        logOut: (state, { payload }) => {
            state.status = 'not-authenticated';
            state.uid = '';
            state.email = '';
            state.displayName = '';
            state.photoURL = '';
            state.errorMessage = payload?.errorMessage;
            state.idTercero = '';
            state.numIdentificacion = '';
            state.tipIdentificacion = '';
            state.genTercero = '';            
            state.tipoUsuario = '';    
            state.nombres = '';
            state.apellidos = '';
            state.telefono = '';
            state.movil = '';
            state.direccion = '';
        },
        checkingCredentials: (state) => {
            state.status = 'checking';
        },
        
    }
});


// Action creators are generated for each case reducer function
export const { logIn, logOut, checkingCredentials } = authSlice.actions;