
export const useLocalStorage = () => {

    const setValue = ( key, value ) => {
        localStorage.setItem( key, value )
    }

    const getValue = ( key ) => {
        if( !localStorage.getItem( key ) ) return '';
        return localStorage.getItem( key );
    }

    const deleteValue = ( key ) => {
        localStorage.removeItem( key );
    }

    return {
        setValue,
        getValue,       
        deleteValue
    }
}
