import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

import { Alert, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

import Link from '@mui/material/Link';
import { Link as RouterLink } from "react-router-dom";

import Paper from '@mui/material/Paper';
import { certificadosApi } from '../../api/certificadosApi';

import { DashboardLayout } from '../../dashboard/layout/DashboardLayout'
import { LoadingData } from '../../ui/components/LoadingData';

export const MisCertificadosPage = () => {

  const { numIdentificacion } = useSelector( state => state.auth );

  const [ listaSolicitudes, setListaSolicitudes ] = useState([]);

  const [ status, setStatus ] = useState('loaded');

  const consultarListaCertificados = async() => {

    if( !numIdentificacion ) return;

    setStatus('loading');
    await certificadosApi.post('getListarSolcitudesCertificados', { identificacion: numIdentificacion })
    .then(resp => setListaSolicitudes( resp.data ))
    .catch( error => console.log( error ) );

    setStatus('loaded');
  }

  useEffect(() => {
    consultarListaCertificados();
  }, [])

  return (
    <DashboardLayout>

        <Grid
            container    
            sx={{ p: 5 }}      
        >

            <Grid item xs={ 12 }>
                <Typography variant="h4" sx={{ mb: 2 }}>Ver los certificados solicitados</Typography>
            </Grid>                    

            <Grid item xs={ 12 } sx={{ mb: 2 }}>
                <Typography>Podras descargar los certificados que se encuentran pagos</Typography>
            </Grid> 

            <Grid item xs={ 12 } sx={{ mb: 2 }}>
              {
                ( status === 'loading' ) ? <LoadingData />

                :

                ( listaSolicitudes.length ) ?

                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">FECHA</TableCell>
                      <TableCell align="center">FECHA PAGO</TableCell>
                      <TableCell align="center">DOCUMENTO</TableCell>
                      <TableCell align="center">NOMBRES</TableCell>
                      <TableCell align="center">EMAIL</TableCell>
                      <TableCell align="center">PRODUCTO</TableCell>
                      <TableCell align="center">PERIODO</TableCell>
                      <TableCell align="center">ORDEN</TableCell>
                      <TableCell align="center">PRODUCTO</TableCell>
                      <TableCell align="center">REFERENCIA</TableCell>
                      <TableCell align="center">VALOR</TableCell>                      
                      <TableCell align="center">ESTADO</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { listaSolicitudes.map((row) => (
                      <TableRow
                        key={ row.secuencia }
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, textAlign: 'center' }}
                      >
                        <TableCell >{ row.fecha_registro }</TableCell>
                        <TableCell >{ row.fecha_estado }</TableCell>
                        <TableCell >{ row.documento }</TableCell>
                        <TableCell >{ row.primer_nombre } { row.primer_apellido }</TableCell>
                        <TableCell >{ row.email_institucional }</TableCell>
                        <TableCell ><b>{ row.descripcion_producto }</b></TableCell>
                        <TableCell ><b>{ row.cod_periodo }</b></TableCell>
                        <TableCell >{ row.orden }</TableCell>
                        <TableCell >{ row.producto }</TableCell>
                        <TableCell >{ row.ref_producto }</TableCell>
                        <TableCell ><b>$ { row.valor }</b></TableCell>
                        <TableCell >{ row.marca_pago }</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          {
                            ( row.marca_pago === 'PAGADO' ) ?
                            <Link
                              variant="body2"
                              href={ `${process.env.REACT_APP_API_URL}/certificados/generarCertificadoPago?secuencia=${ row.secuencia }&identificacion=${ row.documento }` }
                              target='_blank'
                              title="Descargar certificado"
                            >
                              <DownloadForOfflineIcon sx={{ color: 'primary.main' }} />
                            </Link>
                            :   
                            <RouterLink
                              to="/admin/pagos"
                              title="Ir a pagar"
                              >
                              <ProductionQuantityLimitsIcon sx={{ color: 'red' }} />
                            </RouterLink>     
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>   
              
              : <Alert severity='warning'>No se encontraron solicitudes de certificados, recarga nuevamente o realiza una solicitud</Alert>
                
              }
            </Grid>

        </Grid>

    </DashboardLayout>
  )
}
