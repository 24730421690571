import { Stack } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#047835',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: 'white'
  }));

export const DetalleHorario = ({ horario }) => {

  const { nom_materia, doc_docente, nom_docente, dia, hora_inicial, hora_final, nom_aula } = JSON.parse( horario );

  return (
    <Stack spacing={1}>
        <Item>MATERIA: { nom_materia }</Item>
        <Item>DOCENTE: { nom_docente } ( {doc_docente} )</Item>
        <Item>DÍA: { dia }</Item>
        <Item>HORARIO: { hora_inicial } - { hora_final }</Item>
        <Item>AULA DE CLASE: { nom_aula }</Item>
    </Stack>
  )
}
