import React, { useState } from 'react'

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Alert, Divider } from '@mui/material';
import Swal from 'sweetalert2';

import { TipoRespuestaPregunta } from './TipoRespuestaPregunta';
import { LoadingData } from '../../ui/components/LoadingData';

import { formulariosApi } from '../../api/formulariosApi';
import { useEffect } from 'react';

export const Encuesta = ({ claseSeleccionada, mostrarEncuestaClase, consultarAsistenciaClases }) => {

  const [activeStep, setActiveStep] = useState(0);
  const [status, setStatus] = useState('loaded');
  const [preguntas, setPreguntas] = useState([]);
  const [textBoxContents, setTextBoxContents] = useState({});
  const [buttonDisabledForm, setButtonDisabledForm] = useState(false);

  const handleTextboxChange = (preguntaId, value) => {
    setTextBoxContents((prev) => ({ ...prev, [preguntaId]: value }));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    console.log('Datos enviados:', {
      respuestas: preguntas.map((pregunta) => ({
        id: pregunta.id,
        value: pregunta.value,
        texto: textBoxContents[pregunta.id] || '',
      })),
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Asignar el Value de la respuesta seleccionada con su respectiva pregunta
  const respuestaSeleccionada = ({ preguntaId, respuesta }) => {

    const data = [...preguntas];

    const pregunta = data.find(resp => resp.id === preguntaId);

    pregunta.value = respuesta;

    setPreguntas(data);
  }

  const preguntasEncuesta = async() => {

    if( claseSeleccionada.calificado === "1" ) return;
    
    setStatus('loading');

    await formulariosApi.get('/preguntasFormulario')
    .then(resp => {

      setPreguntas(resp.data)
      setStatus('loaded');

    })
    .catch(error => {

      Swal.fire({
        title: '¡Error al cargar las preguntas de la encuesta!',
        text: error.message,
        icon: 'info',
        showCancelButton: false,
        confirmButtonColor: '#047835',
        confirmButtonText: 'Aceptar'
      });

      setPreguntas([]);
      setStatus('loaded');

    });

    
  }  

  const finalizarFormulario = () => {
    setButtonDisabledForm(true);

    const preguntasConTexto = preguntas.map((pregunta) => ({
      ...pregunta,
      textboxValue: textBoxContents[pregunta.id] || '',
    }));

    formulariosApi.post('guardarRespuestasFormulario', {
        clase: claseSeleccionada,
        preguntas: preguntasConTexto,
    })
    .then(resp => {

      if (resp.data.ok == true) {
        // Mensaje de guardedo
        Swal.fire({
            title: '¡Gracias por compartirnos tu opinión!',
            text: resp.data.message,
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: '#047835',
            confirmButtonText: 'Aceptar'
        }).then(conf => { 
            // Limpiar clase seleccionada
            mostrarEncuestaClase({});
  
            // Consultar las clases pendientes
            consultarAsistenciaClases();
        });        
      } else {
        setButtonDisabledForm(false);
        // Mensaje de guardedo
        Swal.fire({
          title: 'Ocurrió un error al momento de guardar.',
          text: resp.data.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#047835',
          confirmButtonText: 'Aceptar'
        }).then(() => { 
            // Limpiar clase seleccionada
            mostrarEncuestaClase({});
            // Consultar las clases pendientes
            consultarAsistenciaClases();
        }); 

      }


    })
    .catch(error => console.log( error ));
  }

  const textosEmojis = (pregunta) => {
    if(pregunta.tipo != 'likert') return;

    return Object.assign({}, [ pregunta['rango_1'], pregunta['rango_2'], pregunta['rango_3'], pregunta['rango_4'], pregunta['rango_5'] ]);
  }

  useEffect(() => {
    preguntasEncuesta();
  }, [])

  // estado de carga de información
  if( status === 'loading' ) return ( <LoadingData /> )

  return (
    <Box sx={{ width: '100%', mt: 2 }}>

      <Typography variant='p' component={'p'}>
        <span style={{ color: '#047835' }}>Fecha y hora asistencia:</span> { ` ${ claseSeleccionada.fecha_asistencia } ${claseSeleccionada.hora_ini} / ${claseSeleccionada.hora_fin}` }
      </Typography>
      
      <Typography variant='p' component={'p'}>
        <span style={{ color: '#047835' }}>Docente:</span> { ` ${ claseSeleccionada.nom_docente }` }
      </Typography>

      <Typography variant='p' component={'p'}>
        <span style={{ color: '#047835' }}>Asignatura:</span> { ` ${ claseSeleccionada.cod_materia }` }
      </Typography>
      
      <Divider sx={{ mt: 2, mb: 2 }} >CALIFICAR CLASE</Divider>


      {  

      // Si la clase seleccionada no ha sido calificada se permite abrir la encuesta
      ( claseSeleccionada.calificado === '0' && preguntas.length ) ?

      <>
      
      <Stepper activeStep={activeStep} orientation="vertical">
        {preguntas.map((pregunta, index) => (
          <Step key={pregunta.label} sx={{ mb: 2 }}>
            <StepLabel
              optional={
                index === (preguntas.length - 1) ? (
                  <Typography variant="caption">Última pregunta</Typography>
                ) : null
              }
            >
              <Typography variant="h5" component="h1">
                {pregunta.label}
              </Typography>
              
            </StepLabel>
            <StepContent>
              <Typography>{pregunta.description}</Typography>

              {/* Renderizar el tipo de respuesta requerido */}
              <Box sx={{ mt: 3 }}>
                <TipoRespuestaPregunta 
                    pregunta={{
                      ...pregunta,
                      textboxValue: textBoxContents[pregunta.id] || ''
                    }}
                    respuestaSeleccionada={ respuestaSeleccionada }
                    textosEmojis= { textosEmojis(pregunta) }
                    handleTextboxChange={handleTextboxChange}
                />
              </Box>

              <Box sx={{ mb: 2, mt: 3 }}>
                <div>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, ml: -1 }}
                  >
                    Atras
                  </Button>
                  <Button
                    variant="contained"
                    onClick={ handleNext }
                    sx={{ mt: 1, mr: 1 }}
                    disabled={pregunta.value === null || pregunta.value === 1 || pregunta.value === 2 ? !textBoxContents[pregunta.id] : false}
                  >
                    {index === preguntas.length - 1 ? 'Finalizar' : 'Continuar'}
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
        </Stepper>

        {(activeStep === preguntas.length) && (
            <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>Completaste todas las preguntas, gracias por tu participación.</Typography>
            
            <Box sx={{ mt: 2 }}>
                <Button 
                  variant="contained" 
                  onClick={ () => finalizarFormulario() }
                  disabled={buttonDisabledForm}
                >
                    Terminar y volver
                </Button>
            </Box>
            </Paper>
        )}

        </>

      :

      (!preguntas.length) ?

        <Alert severity="warning">No se cargaron preguntas para revisar, por favor intenta nuevamente</Alert>

      :

        <Alert severity="success">La clase seleccionada ya fue calificada</Alert>

      }

    </Box>
  );

}