import { useSelector } from "react-redux";
import { Alert, Grid, TextField, Typography } from "@mui/material";

import { DashboardLayout } from "../../dashboard/layout/DashboardLayout"
import { BusquedaEstudiante } from "../../Certificados/components";


export const EstudianteCertificadosPage = () => {

  const { numIdentificacion } = useSelector( state => state.auth );
  
  return (
    <DashboardLayout>

        <Grid
            container    
            sx={{ p: 5 }}      
        >

            <Grid item xs={ 12 }>
                <Typography variant="h4" sx={{ mb: 2 }}>Solicitar certificados</Typography>
            </Grid>                    

            <Grid item xs={ 12 } sx={{ mb: 2 }}>
                <Typography>Selecciona el tipo de certificado que deseas generar</Typography>
            </Grid> 
            
            <Grid item xs={ 12 } sx={{ mt: 3 }}>

              {
                ( !numIdentificacion.length ) ? <Alert severity="warning">No hay un número de identificación del estudiante</Alert> : 

                <>
                  <Grid item xs={ 12 } sx={{ mb: 3 }}>
                    <TextField
                        label="Identificación del estudiante" 
                        type="text" 
                        placeholder="Identificación del estudiante"
                        fullWidth
                        name="identificacionEstudiante"
                        value={ numIdentificacion }
                        disabled={true}
                        />
                  </Grid>                  

                  <BusquedaEstudiante identificacionEstudiante={ numIdentificacion } tipoUsuario='ESTUDIANTE'/>
                </>

              }

            </Grid>

        </Grid>     
      
    </DashboardLayout>
  )
  
}