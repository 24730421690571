import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "../../hooks";
import { LoadingData } from "../../ui/components/LoadingData";
import { DetalleHorario } from "../components/DetalleHorario";
import { BusquedaHorariosDocente } from "../components/BusquedaHorariosDocente";
import { Alert, Button, Grid, InputLabel, NativeSelect, Typography } from "@mui/material"
import { DashboardLayout } from "../../dashboard/layout/DashboardLayout"
import { docentesApi } from "../../api/docentesApi";
import { Close, ReplayOutlined } from "@mui/icons-material";
import { DetalleEstudiantes } from "../components";
import Swal from "sweetalert2";

export const AsistenciaEstudiantePage = () => {
  const [ status, setStatus ] = useState();
  const [ horarios, setHorarios ] = useState([]);
  const [ estudiantesHorarios, setEstudiantesHorarios ] = useState([]);
  const [ esAdmin, setEsAdmin ] = useState(false);
  const { email, displayName, numIdentificacion, tipoUsuario  } = useSelector( state => state.auth );

  const inputsForm = {
    horarioClase: '',
  }  

  const { 
    //formState, 
    horarioClase,
    handleInputChange,
    handleResetForm,
    //isFormValid,
    //horarioClaseValid,
  } = useForm( inputsForm );   

  useEffect(() =>  {
    handleConsultarHorarioDocente();
  }, [email] );

  // 
  const handleConsultarHorarioDocente = () => {
    consultarHorariosDocente({ email, identificacion: numIdentificacion, esAdmin: false });
  }

  // Consultar servicio para extraer los horarios del docente
  const consultarHorariosDocente = async({ email, identificacion, num_dia = '', esAdmin = false }) =>  {

    // Identifica el tipo de consulta realizada por uusario o administrador
    setEsAdmin( esAdmin );
    
    setStatus('loading');
    await docentesApi.post('informacionHorarioDocente', { email, identificacion, num_dia })
    .then(result => {
      setHorarios( result.data.horarios );
      handleResetForm();
      setStatus('loaded');
    })
    .catch(error => {
      setStatus('loaded');
      Swal.fire({
        title: 'Error horarios',
        text: `Error al consultar horarios de clase docente: ${displayName} - ${error.message}`,
        icon: 'error',
        confirmButtonColor: '#047835',
        confirmButtonText: 'Aceptar'
      });      
    });    
  };  

  const onSelectChange = async(event) => {
    handleInputChange(event);
    setStatus('loading');

    // Al realizar el evento en el select de horario de clase entonces de consultan los estudiante de la clase seleccionada
    const bodyHorario = JSON.parse(event.target.value);
    await docentesApi.post('informacionEstudiantesHorario', { ...bodyHorario })
    .then(result => {
      setEstudiantesHorarios( result.data.estudiantes );
      setStatus('loaded');
    })
    .catch(error => {
      console.log('Error al consultar el servicio ');
      setStatus('loaded')
    });
  }

  // Si la busqueda de horarios de clase del docente tiene información se muestra el componente DetalleHorario
  const ValidacionHorario = () => {
    if( horarioClase.length ) return <DetalleHorario horario={ horarioClase }/>;
    
    return <Alert severity="warning">Ningún horario ha sido seleccionado</Alert>;
  }

  // Validar si hay un horario de clase seleccionado y existen registros para ese horario seleccionado
  const ValidacionDetalleHorario = () => {
    if( horarioClase.length && estudiantesHorarios.length ){
      return <DetalleEstudiantes listaEstudiantes={ estudiantesHorarios } horario={ horarioClase } esAdmin={ esAdmin } numIdentificacionDocente={numIdentificacion} /> 
    }
    
    return <Alert severity="warning">No hay detalles del horario</Alert>
  }

  return (
    <DashboardLayout title="Asistencia estudiante">
      
      <Grid container sx={{ p: 2 }}>

      { ( status === 'loading' ) && <LoadingData /> }

      {
        ( status === 'loaded' ) && 
        
        <Grid item xs={ 12 } sx={{ mt: 3 }}>
            
          <Grid item xs={ 12 }>
              <Typography variant="h4" sx={{ mb: 2 }}>Marcar asistencia de clase.</Typography>
          </Grid>       

          <Grid item xs={ 12 }>
              <Typography variant="p" sx={{ mb: 2 }}>
                Docente: { displayName }
              </Typography>
          </Grid>                              

          <Grid item xs={ 12 }>
              <Typography variant="p" sx={{ mb: 2 }}>
                Correo: { email }
              </Typography>
          </Grid>                              

          <Grid item xs={ 12 } sx={{ mb: 2, mt: 2 }}>
              <Typography>A continuación encontrara los horarios de asociados al calendario </Typography>
          </Grid>          
          
          <Grid item xs={ 12 } sx={{ mt: 3 }}>
              <InputLabel id="horarioClaseLbl" htmlFor="horarioClase">Horario de clase</InputLabel>
              <NativeSelect fullWidth
                native="true"
                id="horarioClase"
                value={ horarioClase }
                label="horarioClase"
                name="horarioClase"
                onChange={ onSelectChange }
                disabled={ !!horarioClase.length }
              >
                <option defaultValue="">Seleccione</option>
                {
                  horarios?.map((horario, index) => (
                      <option 
                          key={ index }
                          value={ JSON.stringify(horario) }
                      >
                          ({ horario.nom_materia }) - { horario.dia } - { horario.hora_inicial } - { horario.hora_final }
                      </option>
                  ))
                }
              </NativeSelect>
          </Grid>

          <Grid item xs={ 12 }>
            <Button
                variant="text"
                onClick={ () => handleResetForm() }
                disabled={ ( !horarioClase.length ) ? true : false }
                fullWidth
                color="error"
                startIcon={ <Close /> }
                >
              Seleccionar otro horario
            </Button>
          </Grid>
          
          <Grid item xs={ 6 }>
            <Button
              onClick={ handleConsultarHorarioDocente }
              startIcon={ <ReplayOutlined /> }
            >
              Recargar horarios
            </Button>
          </Grid> 
          
          {
            // El componente se muestra solamente al usuario Administrador de la herramienta
            (tipoUsuario === 'SUPERADMIN') &&           
            <Grid item xs={ 6 }>
              <BusquedaHorariosDocente 
                consultarHorariosDocente={ consultarHorariosDocente }
              />
            </Grid> 

          }                  
          
          <Grid item xs={ 12 } sx={{ mt: 3 }}>
            { <ValidacionHorario /> }
          </Grid>


          <Grid item xs={ 12 } sx={{ mt:3 }}>
            { <ValidacionDetalleHorario /> }
          </Grid>

        </Grid>
      }

      </Grid>

    </DashboardLayout>
  )
}
