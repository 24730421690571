import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from './auth';
import { estudiantesApi } from './api/estudiantesApi';
import { appSlice } from './app';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    app: appSlice.reducer,
    [estudiantesApi.reducerPath]: estudiantesApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(estudiantesApi.middleware)
});

export const getState = store.getState();