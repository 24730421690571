import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";

export const purpleTheme = createTheme({
    palette: {
        primary: {
            main: '#047835'
        },
        secondary: {
            main: '#1F2936'
        },
        third: {
            main: '#80BC04'
        },        
        error: {
            main: red.A400,
        }
    }
});