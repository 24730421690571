import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { Box } from '@mui/system'
import { Alert, BottomNavigation, BottomNavigationAction, CssBaseline, Grid, IconButton, List, Paper, Typography } from '@mui/material'
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

import { DashboardLayout } from '../../dashboard/layout/DashboardLayout'
import { ItemEncuesta, Encuesta } from '../components';

import { estudiantesApi } from  '../../api/estudiantesApi';
import { LoadingData } from '../../ui/components/LoadingData';

export const EncuestaSatisfaccionPage = () => {

  let { numIdentificacion, displayName } = useSelector( state => state.auth );

  const [value, setValue] = useState(0);

  const [status, setStatus] = useState('loaded');

  const [claseSeleccionada, setClaseSeleccionada] = useState({});

  const ref = useRef(null);

  const [listaClases, setListaClases] = useState([]);

  const mostrarEncuestaClase = (clase) => {
    setClaseSeleccionada(clase);
  }

  const TextoCalificacionClases = () => {
    let tituloClasesCalificar = 'Clases calificadas';
    if( value === 0 ){
      tituloClasesCalificar = 'Clases por calificar';
    }
    return tituloClasesCalificar;
  }
  
  const Texto1 = () => {
    let text1 = 'Aquí puedes ver las encuestas que ya respondiste.'
    if( value === 0 ){
      text1 = 'Te invitamos a darnos tu opinión para mejorar nuestras clases.';
    }
    return text1;
  }

  const Texto2 = () => {
    let text2 = 'No olvides validar que tus encuestas fueron guardadas de manera correcta'
    if( value === 0 ){
      text2 = 'No olvides que de no responder la encuesta, se tomará como inasistencia a la clase.';
    }
    return text2;
  }
  
  const Texto3 = () => {
    let text3 = 'A continuación podrás ver el listado de cada una de tus encuestas.'
    if( value === 0 ){
      text3 = 'A continuación, tendrás algunas preguntas que nos permitirán conocer tu opinión frente a las clases recibidas.';
    }
    return text3;
  }

  const consultarAsistenciaClases = async () => {
    setStatus('loading');
    await estudiantesApi.post('/asistenciaEstudiante', { identificacion: numIdentificacion, modo: ( value === 0 ) ? 'pendiente' : 'calificados' })
    .then(resp => setListaClases(resp.data))
    .catch(error => console.log( error ))

    setStatus('loaded');
  }  

  useEffect(() => {

    if( numIdentificacion ) {
      ref.current.ownerDocument.body.scrollTop = 0;
      consultarAsistenciaClases();
      mostrarEncuestaClase({});
    }

  }, [ value ]);

  return (
    <DashboardLayout>

        <Grid container sx={{ pr:3, pl: 3, minHeight: '500px', mt: 4 }}>

          <Grid item xs={ 12 } sx={{ textAlign: 'justify', p: 2 }} >

            <h3>Hola, <span style={{ textTransform: 'capitalize' }}>{ displayName.toLowerCase() }</span> </h3>

            <p><Texto1 /></p>
            
            <Typography color={ 'primary.main' }>
              <i><p><Texto2 /></p></i>
            </Typography>

            <p><Texto3 style={{ color: 'primary.main' }} /></p>
            
            <Typography variant="h6" align='center'>
              <TextoCalificacionClases value="tituloClasesCalificar" /> { listaClases.length }.
            </Typography>

          </Grid>   
          
          { ( numIdentificacion) ?

            <Grid item xs={ 12 }>

                <Box sx={{ pb: 7 }} ref={ref}>
                    <CssBaseline />
                    {

                      ( status === 'loading' ) ? <LoadingData />

                      :

                      ( !claseSeleccionada.id ) ? 

                      // Lista de clases para calificar
                      <List>
                        {listaClases.map((clase, index) => (
                            <ItemEncuesta 
                              key={ index } 
                              clase={ clase }
                              mostrarEncuestaClase={ mostrarEncuestaClase }
                            />
                        ))}
                      </List>

                      : 
                      
                      // Abrir encuesta y accion para volver atras
                      <>
                        <Grid sx={{ textAlign: 'left',  ml: -1 }}>
                          <IconButton
                            size="small"
                            edge="end"
                            aria-label="Volver a la página principal"
                            aria-haspopup="true"
                            onClick={ () => mostrarEncuestaClase({}) }
                            sx={{ color: 'primary.main' }}
                          >
                            <KeyboardBackspaceOutlinedIcon /> Volver
                          </IconButton>
                        </Grid>

                        <Grid sx={{ backgroundColor: 'white' }}>

                          <Encuesta 
                            claseSeleccionada={ claseSeleccionada }
                            mostrarEncuestaClase={ mostrarEncuestaClase } 
                            consultarAsistenciaClases={ consultarAsistenciaClases }
                          />

                        </Grid>
                      </>
                    }

                    {/* Barra de acciones inferior */}
                    <Paper sx={{ position: 'fixed', top: 65, left: 0, right: 0 }} elevation={3}>
                          <BottomNavigation
                            showLabels
                            value={value}
                            onChange={(event, newValue) => setValue(newValue)}
                          >
                            <BottomNavigationAction label="Pendientes" icon={<PendingActionsIcon />} />
                            <BottomNavigationAction label="Calificados" icon={<DoneAllIcon />} />
                        </BottomNavigation>
                    </Paper>
                </Box>

            </Grid>

            :
            
            <Grid item xs={ 12 }>
              <Alert severity="error">Usuario sin número de identificación.</Alert>
            </Grid>

          }            

        </Grid>

    </DashboardLayout>
  )
}