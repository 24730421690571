
export const useCookies = () => {

    const setCookie = ({ cookieName = '', cookieValue = '', expires = '' }) => {
        document.cookie = `${ cookieName }=${ cookieValue }; expires=${ expires }`;
    }

    const getCookie = ( cookieName = '' ) => {
        let name = cookieName + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }


    const removeCookie = ( cookieName = '' ) => {
        document.cookie = cookieName +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }


    return {
        setCookie,
        getCookie,
        removeCookie  
    }
}
