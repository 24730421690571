import { CircularProgress, Grid } from '@mui/material'

export const LoadingData = () => {
  return (
    <Grid 
        container
        spacing={ 0 }
        direction="column"
        alignItems="center"
        justifyContent="center"
    >

        <Grid 
            container
            direction="row"
            justifyContent="center"
        >   

          <CircularProgress color="primary"/>
        
        </Grid>

        <p> Cargando, por favor espere ... </p>
    </Grid>
  )
}
