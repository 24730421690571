import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { 
    Box,
    Divider, 
    Drawer, 
    List, 
    ListItem, 
    ListItemButton, 
    ListItemIcon, 
    ListItemText, 
    Toolbar, 
    Typography,
    Grid,
} from "@mui/material";

import { NavigateNext } from "@mui/icons-material";
import Swal from "sweetalert2";
import { getMenuUser } from "../../store/app/thunks";
import { useEffect } from "react";

export const SideBar = ({ drawerWidth = 240, openSidebar, isOpenSidebar = false }) => {

    const dispatch = useDispatch();

    const { email, displayName } = useSelector(state => state.auth);
    const { status, errorMessage: error, menuApp: menu } = useSelector(state => state.app);

    useEffect(() =>  {

        // Ejecutar menu del usuario
        dispatch( getMenuUser({ email, menu }) );

    }, [ email ])

    if( error ){

        Swal.fire({
            title: 'Error menú',
            text: error,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#047835',
            confirmButtonText: 'Aceptar'
        });

    }
    
    const displaySidebar = () => {
        if( isOpenSidebar ) return 'block';

        return 'none';
    }

    return (
    <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, display: displaySidebar }}
        onClick={ () => openSidebar() }
    >

        <Drawer
            variant="temporary"
            open={ isOpenSidebar }
            sx={{ 
                //display: { xs: 'none', md: 'block' }, 
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'secondary.main', color: 'white' },
            }}
        >

            <Toolbar>
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                >{ displayName }</Typography>
            </Toolbar>

            <Divider />

            <List>
                {
                    (status === 'loading') && (
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <NavigateNext sx={{ color: '#80BC04' }}/>
                                </ListItemIcon>

                                <Grid container>
                                    {/* <ListItemText primary={ item } secondary="lorem"/> */}
                                    <ListItemText primary={ 'Cargando menú, por favor espere...' }/>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    )
                }

                {
                    menu.map(item => (
                        <ListItem key={ item.id } disablePadding>
                            <ListItemButton component={ RouterLink } to={ `${ item.ruta }` }>
                                <ListItemIcon>
                                    <NavigateNext sx={{ color: '#80BC04' }}/>
                                </ListItemIcon>

                                <Grid container>
                                    {/* <ListItemText primary={ item } secondary="lorem"/> */}
                                    <ListItemText primary={ item.title }/>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    ))
                }
            </List>

        </Drawer>

    </Box>
    )
}
