import { Routes, Route, Navigate } from "react-router-dom";

import { AuthRoutes } from "../auth/routes/AuthRoutes";
import { useCheckAuth } from "../hooks";
import { DashboardRoutes } from "../dashboard/routes/DashboardRoutes";
import { CheckingAuth } from "../ui";
import { EncuestaSatisfaccionNotificacionPage } from "../Estudiantes/pages/EncuestaSatisfaccionNotificacionPage";

export const AppRouter = () => {

    const { status } = useCheckAuth();

    if( status === 'checking' ){
        return <CheckingAuth />
    }

    return (    
        <Routes>

            { 
            
                (status === 'authenticated') 
                
                    ? <Route path="/*" element={ <DashboardRoutes /> }/>
                    : <Route path="auth/*" element={ <AuthRoutes /> }/> 
            
            }

            {/* Ruta publica para acceder desde la URL a la encuesta de satisfaccion */}
            <Route path="/estudiantes/encuestaSatisfaccion/:idAsistencia" element={ <EncuestaSatisfaccionNotificacionPage /> } />
            
            <Route path="/*" element={ <Navigate to="/auth/login" /> } />

        </Routes>
    )
}