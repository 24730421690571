import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import ClearIcon from '@mui/icons-material/Clear';
import { Checkbox, FormControlLabel, Grid, RadioGroup, Typography, IconButton, InputBase, Paper } from '@mui/material';

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Muy insatisfecho',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Insatisfecho',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Satisfecho',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Muy Satisfecho',
  },
};

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconFilled .MuiSvgIcon-root': {
    fontSize: '10vw',
    marginLeft: '2.1vw'
  },
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
    fontSize: '10vw',
    marginLeft: '2.1vw'
  },
  '& .MuiTypography-root': {
    color: 'black',
  },
}));

const IconContainer = (props) => {
  const { value, ...other } = props;
  const textoIcon = customIcons[value]?.label || '';
  return (
    <table style={{ width: '100%', margin: 5, textAlign: 'center' }}>
      <tbody>
        <tr>
          <td style={{ padding: '1px 1px 1px 3px' }}>
            <span {...other}>{customIcons[value]?.icon}</span>
          </td>
        </tr>
        <tr>
          <td style={{ right: 1 }}>
            <Typography variant="subtitle2" sx={{ mt: 1 }}>{textoIcon}</Typography>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export const TipoRespuestaPreguntaDocente = ({ pregunta, respuestaSeleccionada, textosEmojis, handleTextboxChange }) => {
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [showTextbox, setShowTextbox] = useState(false);
  const [textboxValue, setTextboxValue] = useState(pregunta.textboxValue || '');
  const maxChars = 100;

  useEffect(() => {
    if (pregunta.value === 1 || pregunta.value === 2) {
      setShowTextbox(true);
      setTextboxValue(pregunta.textboxValue || '');
    } else {
      setShowTextbox(false);
      setTextboxValue('');
    }
  }, [pregunta]);

  const handleRespuesta = (respuesta) => {
    if (pregunta.tipo === 'likert') {
      if (respuesta === 1 || respuesta === 2) {
        setShowTextbox(true);
      } else {
        setShowTextbox(false);
        handleTextboxChange(pregunta.id, '');
      }
      respuestaSeleccionada({ preguntaId: pregunta.id, respuesta });
    } else if (pregunta.tipo === 'checkbox') {
      setCheckboxOptions(prevOptions => {
        const updatedOptions = [...prevOptions];
        const optionIndex = updatedOptions.findIndex(opt => opt.title === respuesta);

        if (optionIndex >= 0) {
          updatedOptions.splice(optionIndex, 1);
        } else {
          updatedOptions.push({ title: respuesta, value: respuesta });
        }

        const respuestasArr = updatedOptions.map(opt => opt.value);
        respuestaSeleccionada({ preguntaId: pregunta.id, respuesta: respuestasArr.length ? respuestasArr : null });

        return updatedOptions;
      });
    } else if (pregunta.tipo === 'text') {
      handleTextboxChange(pregunta.id, respuesta);
    }
  };

  const respuestasMaximasPermitidas = (Array.isArray(pregunta.value) ? pregunta.value.length : 0) >= 4;

  const limpiarCheckbox = () => {
    respuestaSeleccionada({ preguntaId: pregunta.id, respuesta: '' });
    setCheckboxOptions([]);
  };

  return (
    <>
      {pregunta.tipo === 'likert' && (
        <>
          <StyledRating
            onChange={(event, newValue) => handleRespuesta(newValue)}
            name="highlight-selected-only"
            defaultValue={pregunta.value}
            IconContainerComponent={IconContainer}
            getLabelText={(value) => customIcons[value]?.label}
            highlightSelectedOnly
          />
          {showTextbox && (
            <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
              <Paper
                component="div"
                sx={{ p: '3px 5px', display: 'flex', alignItems: 'center', width: '100%' }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Ingresa tu respuesta"
                  fullWidth
                  type="text"
                  name="respuestaTexto"
                  autoComplete="off"
                  value={textboxValue}
                  onChange={(event) => {
                    setTextboxValue(event.target.value);
                    handleTextboxChange(pregunta.id, event.target.value);
                  }}
                  inputProps={{ maxLength: maxChars }}
                />
                <Typography variant='caption' sx={{ ml: 2 }}>
                  {textboxValue.length}/{maxChars}
                </Typography>
              </Paper>
            </Grid>
          )}
        </>
      )}
      {pregunta.tipo === 'checkbox' && (
        <>
          <Grid sx={{ textAlign: 'left' }}>
            <IconButton
              size="small"
              edge="end"
              aria-label="Limpiar campos seleccionado de la encuesta"
              onClick={limpiarCheckbox}
              sx={{ color: 'primary.main' }}
              type="reset"
            >
              <ClearIcon /> Limpiar campos seleccionados
            </IconButton>
          </Grid>

          <RadioGroup
            aria-labelledby={'radio-buttons-group-label' + pregunta.id}
            name={'checkbox_' + pregunta.id}
          >
            {checkboxOptions.map(check => (
              <FormControlLabel
                key={check.title}
                control={
                  <Checkbox
                    value={check.title}
                    checked={checkboxOptions.some(opt => opt.title === check.title)}
                    onChange={event => handleRespuesta(event.target.value)}
                    disabled={(check.title !== 'La clase estuvo muy bien') ? respuestasMaximasPermitidas : false}
                  />
                }
                label={check.title}
              />
            ))}
          </RadioGroup>
        </>
      )}
      {pregunta.tipo === 'text' && (
        <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
          <Paper
            component="div"
            sx={{ p: '3px 5px', display: 'flex', alignItems: 'center', width: '100%' }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Ingresa tu respuesta"
              fullWidth
              type="text"
              name="respuestaTexto"
              autoComplete="off"
              value={textboxValue}
              onChange={(event) => {
                setTextboxValue(event.target.value);
                handleRespuesta(event.target.value);
              }}
              inputProps={{ maxLength: 100 }}
            />
            <Typography variant='caption' sx={{ ml: 2 }}>
              {textboxValue.length}/100
            </Typography>
          </Paper>
        </Grid>
      )}
    </>
  );
};

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

TipoRespuestaPreguntaDocente.propTypes = {
  pregunta: PropTypes.shape({
    tipo: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    textboxValue: PropTypes.string,
    id: PropTypes.string.isRequired
  }).isRequired,
  respuestaSeleccionada: PropTypes.func.isRequired,
  textosEmojis: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleTextboxChange: PropTypes.func.isRequired
};
